import initFancybox from '../utils/initFancybox';

const selectors = {
    gallery: '.js-fancybox-gallery'
};

const options = {
    protect: true,
    backFocus: false
};

const fancyboxGallery = () => {
    const $gallery = $(selectors.gallery);

    if (!$gallery.length) {
        return;
    }

    initFancybox($gallery, options);
};

export default fancyboxGallery;
